@import "leaflet/dist/leaflet";

@import "tailwindcss/dist/base";
@import "tailwindcss/dist/components";
@import "tailwindcss/dist/utilities";

#map {
    width: 100%;
    height: 85vh;
}

.intro {
    max-width: 620px;
    @apply mx-auto;
}

.route-info {
    max-height: 300px;
    overflow-y: scroll;
}

.donut-shop-icon {
    background: none;
    border: none;
}

.hover-underline:hover {
    text-decoration: underline;
}

.route-item {
    @apply px-2 border border-gray-400 text-gray-700 truncate;
    @apply cursor-pointer;
}

.route-item:nth-child(odd) {
    @apply bg-gray-200;
}
